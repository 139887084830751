import React from 'react';

import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';

import { KeyboardDatePicker } from 'formik-material-ui-pickers';

/**
 * Convert a _local-time_ value to an ISO-8601 Date string.
 *
 * For instance: given 2020-05-13T03:59:50.000Z, if we're in UTC-4,
 * return "2020-05-12".
 *
 * Why? Because material-ui selects dates in local time, not in UTC. If we
 * were to run date.toISOString(), that would convert to UTC and then
 * convert to String; but if we convert to UTC, that changes the date.
 */
const jsDateToLocalISO8601DateString = (dateObj) => {
  const year = dateObj.getFullYear();
  const month = String(101 + dateObj.getMonth()).substring(1);
  const day = String(100 + dateObj.getDate()).substring(1);
  return [year, month, day].join('-');
};

const MyKeyboardDatePicker = (props) => {
  const { setFieldValue } = useFormikContext();
  const { field } = props;

  return (
    <KeyboardDatePicker
      {...props}
      name={field.name}
      format="dd/MM/yyyy"
      onChange={(value) => {
        // override bindings to prevent issue with timezone
        if (!value) return;
        const date = jsDateToLocalISO8601DateString(value);
        setFieldValue(field.name, date);
      }}
    />
  );
};

MyKeyboardDatePicker.propTypes = {
  props: PropTypes.any,
};

export default MyKeyboardDatePicker;
