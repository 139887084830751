import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import { Field, Formik, Form } from 'formik';
import { Box, Typography, FormControlLabel, Radio } from '@material-ui/core';

import { TextField, RadioGroup } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import KeyboardDatePicker from 'components/KeyboardDatePicker';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_PARTNER } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '70ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '60ch',
  },
}));

const InfoApportsPartenaire = ({
  partner,
  // formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
  // capital_in_numbers = 5000,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();
  return (
    <Formik
      initialValues={partner}
      // validationSchema={() => {
      //   return Yup.object().shape({
      //     cash_funds_numbers: Yup.number().when(
      //       'supplies_funds_numbers',
      //       (supplies_funds_numbers) => {
      //         if (supplies_funds_numbers && capital_in_numbers) {
      //           return Yup.number()
      //             .min(capital_in_numbers - supplies_funds_numbers)
      //             .max(capital_in_numbers - supplies_funds_numbers)
      //             .required('Required');
      //         }
      //       }
      //     ),

      //     apport_nature: Yup.string().required('un choix est necessaire'),

      //     apport_fonds_de_commerce: Yup.string().required(
      //       'un choix est necessaire'
      //     ),

      //     date_apport_fonds_de_commerce: Yup.date().when(
      //       'apport_fonds_de_commerce',
      //       (apport_fonds_de_commerce) => {
      //         if (apport_fonds_de_commerce === 'oui') {
      //           return Yup.date().required('Required');
      //         }
      //       }
      //     ),

      //     apport_titres_societe: Yup.string().required(
      //       'un choix est necessaire'
      //     ),

      //     date_apport_titres_societe: Yup.date().when(
      //       'apport_titres_societe',
      //       (apport_titres_societe) => {
      //         if (apport_titres_societe === 'oui') {
      //           return Yup.date().required('Required');
      //         }
      //       }
      //     ),

      //     raison_sociale_societe_apport_titres_societe: Yup.string().when(
      //       'apport_titres_societe',
      //       (apport_titres_societe) => {
      //         if (apport_titres_societe === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     nombre_titres_apportes_apport_titres_societe: Yup.number().when(
      //       'apport_titres_societe',
      //       (apport_titres_societe) => {
      //         if (apport_titres_societe === 'oui') {
      //           return Yup.number().required('Required');
      //         }
      //       }
      //     ),

      //     nombre_titres_total_apport_titres_societe: Yup.number().when(
      //       'apport_titres_societe',
      //       (apport_titres_societe) => {
      //         if (apport_titres_societe === 'oui') {
      //           return Yup.number().required('Required');
      //         }
      //       }
      //     ),

      //     apport_nature_divers: Yup.string().required(
      //       'un choix est necessaire'
      //     ),

      //     date_apport_nature_divers: Yup.date().when(
      //       'apport_nature_divers',
      //       (apport_nature_divers) => {
      //         if (apport_nature_divers === 'oui') {
      //           return Yup.date().required('Required');
      //         }
      //       }
      //     ),

      //     description_biens_apport_nature_divers: Yup.string().when(
      //       'apport_nature_divers',
      //       (apport_nature_divers) => {
      //         if (apport_nature_divers === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     apport_industrie: Yup.string().required('un choix est necessaire'),

      //     expertise_funds_numbers: Yup.number().when(
      //       'apport_industrie',
      //       (apport_industrie) => {
      //         if (apport_industrie === 'oui') {
      //           return Yup.number().required('Required');
      //         }
      //       }
      //     ),
      //   });
      // }}
      onSubmit={(values, { setSubmitting }) => {
        // eslint-disable-next-line
        values.partner_supplies_funds_numbers =
          parseInt(values.partner_valeur_apport_fonds_de_commerce, 10) +
          parseInt(values.partner_valeur_apport_titres_societe, 10) +
          parseInt(values.partner_valeur_apport_nature_divers, 10);

        dispatch({
          type: EDIT_PARTNER,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap === true) {
        //   SciAutomate.goRecap();
        //   setAutomateState(SciAutomate.state);
        // }

        SciAutomate.nextInfoApportsPartenaireVersInfoLiberationPartenaire();
        setAutomateState(SciAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              width="100%"
            >
              <Box className={classes.root} width="100%">
                <Typography variant="h6">
                  Les apports à la société en création par l associé
                </Typography>
              </Box>

              <Field
                component={RadioGroup}
                defaultValue="non"
                name="partner_apport_numeraire"
              >
                <Box>
                  <Typography variant="subtitle1">
                    Y a-t-il un apport en numéraire ?
                  </Typography>
                  <FormControlLabel
                    value="non"
                    control={<Radio />}
                    label="non"
                  />
                  <FormControlLabel
                    value="oui"
                    control={<Radio />}
                    label="oui"
                  />
                </Box>
              </Field>

              {values.partner_apport_numeraire === 'oui' && (
                <Fragment>
                  <Box className={classes.root} width="100%">
                    <Field
                      width="100%"
                      component={TextField}
                      type="string"
                      label="Apports en numéraire - montant en chiffres "
                      name="partner_cash_funds_numbers"
                      className={classes.textField}
                    />
                  </Box>

                  {/* 
                  <Box className={classes.root} width="100%">
                    <Field
                      width="100%"
                      component={TextField}
                      type="string"
                      label="Nom de la banque ou le dépôt a été fait"
                      name="partner_nom_banque_cash_funds"
                      className={classes.textField}
                    />
                  </Box>


                  <Box className={classes.root} width="100%">
                    <Field
                      width="100%"
                      component={TextField}
                      type="string"
                      label="Nom de la banque ou le dépôt a été fait"
                      name="partner_adresse_banque_cash_funds"
                      className={classes.textField}
                    />
                  </Box> */}
                </Fragment>
              )}

              <Box className={classes.root} width="100%">
                <Field
                  component={RadioGroup}
                  defaultValue="non"
                  name="partner_apport_nature"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      Y a-t-il un apport en nature ?
                    </Typography>
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="non"
                    />
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="oui"
                    />
                  </Box>
                </Field>

                {values.partner_apport_nature === 'oui' && (
                  <Fragment>
                    <Box className={classes.root} width="100%">
                      <Field
                        component={TextField}
                        type="string"
                        label="Apports en nature total - montant en chiffres "
                        name="partner_supplies_funds_numbers"
                        className={classes.textField}
                      />
                    </Box>
                  </Fragment>
                )}
              </Box>

              {/* <Box className={classes.root}>
                <Field
                  component={RadioGroup}
                  defaultValue="non"
                  name="partner_apport_fonds_de_commerce"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      Y a-t-il un apport de fonds de commerce ?
                    </Typography>
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="non"
                    />
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="oui"
                    />
                  </Box>
                </Field>

                {values.partner_apport_fonds_de_commerce === 'oui' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Field
                        component={KeyboardDatePicker}
                        clearable
                        label="Date de l'apport"
                        placeholder="01/01/1990"
                        format="dd/MM/yyyy"
                        name="partner_date_apport_fonds_de_commerce"
                        className={classes.birthdayField}
                      />

                      <Field
                        component={TextField}
                        type="string"
                        label="Evaluation de l'apport - montant en chiffres"
                        name="partner_valeur_apport_fonds_de_commerce"
                        className={classes.textField}
                      />
                    </Box>
                  </Fragment>
                )}
              </Box> */}

              <Box className={classes.root} width="100%">
                <Field
                  component={RadioGroup}
                  defaultValue="non"
                  name="partner_apport_titres_societe"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      Y a-t-il un apport de titres de société ?
                    </Typography>
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="non"
                    />
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="oui"
                    />
                  </Box>
                </Field>

                {values.partner_apport_titres_societe === 'oui' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Field
                        component={KeyboardDatePicker}
                        clearable
                        label="Date de l'apport"
                        placeholder="01/01/1990"
                        format="dd/MM/yyyy"
                        maxDate={new Date()}
                        name="partner_date_apport_titres_societe"
                        className={classes.birthdayField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Evaluation de l'apport - montant en chiffres"
                        name="partner_valeur_apport_titres_societe"
                        className={classes.textField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Raison sociale de la société apportée"
                        name="partner_raison_sociale_societe_apport_titres_societe"
                        className={classes.textField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Nombre de titres apportés"
                        name="partner_nombre_titres_apportes_apport_titres_societe"
                        className={classes.textField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Nombre de titres total composant le capital de la société apportée"
                        name="partner_nombre_titres_total_apport_titres_societe"
                        className={classes.textField}
                        autoComplete="off"
                      />
                    </Box>
                  </Fragment>
                )}
              </Box>
              <Box className={classes.root} width="100%">
                <Field
                  component={RadioGroup}
                  defaultValue="non"
                  name="partner_apport_nature_divers"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      Y a t-il des apports en nature divers ? ( matériel
                      informatique, véhicule, site internet etc... ){' '}
                    </Typography>
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="non"
                    />
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="oui"
                    />
                  </Box>
                </Field>

                {values.partner_apport_nature_divers === 'oui' && (
                  <Fragment>
                    <Box marginRight="30px" className={classes.root}>
                      <Field
                        component={KeyboardDatePicker}
                        clearable
                        label="Date de l'apport"
                        placeholder="01/01/1990"
                        format="dd/MM/yyyy"
                        maxDate={new Date()}
                        name="partner_date_apport_nature_divers"
                        className={classes.birthdayField}
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Evaluation de l'apport en nature divers - montant en chiffres"
                        name="partner_valeur_apport_nature_divers"
                        className={classes.textField}
                        autoComplete="off"
                      />
                      <Box alignSelf="center" marginTop="10px">
                        <Field
                          component={TextField}
                          type="string"
                          label="Veuillez décrire les biens apportés"
                          name="partner_description_biens_apport_nature_divers"
                          className={classes.textField}
                          fullwidth="true"
                          multiline
                          rows="4"
                          autoComplete="off"
                        />
                      </Box>
                    </Box>
                  </Fragment>
                )}
              </Box>

              {/* <Box className={classes.root}>
                <Field
                  component={RadioGroup}
                  defaultValue="non"
                  name="partner_apport_industrie"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      Y a t-il des apports en industrie ?{' '}
                    </Typography>
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="non"
                    />
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="oui"
                    />
                  </Box>
                </Field>
              </Box>

              {values.partner_apport_industrie === 'oui' && (
                <Fragment>
                  <Box className={classes.root}>
                    <Field
                      component={TextField}
                      type="string"
                      label="Apports en industrie - montant en chiffres "
                      name="partner_expertise_funds_numbers"
                      className={classes.textField}
                      autoComplete="off"
                    />
                  </Box>
                </Fragment>
              )} */}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};
InfoApportsPartenaire.propTypes = {
  nextForm: PropTypes.func,
  capital_in_numbers: PropTypes.number,
};
export default InfoApportsPartenaire;
