import React, { createContext } from 'react';

import useLocalStorageReducer from '../hooks/useLocalStorageReducer';
import formReducer from '../reducers/form.reducer';
import defaultForm from '../models/initial.context';

export const DispatchContext = createContext();

export const FormContext = createContext();

export const FormProvider = (props) => {
  const defaultValueForm =
    JSON.parse(localStorage.getItem('form')) || defaultForm();
  const [form, dispatch] = useLocalStorageReducer(
    'form',
    formReducer,
    defaultValueForm
  );

  return (
    <FormContext.Provider value={form}>
      <DispatchContext.Provider value={dispatch}>
        {/* eslint-disable-next-line react/prop-types */}
        {props.children}
      </DispatchContext.Provider>
    </FormContext.Provider>
  );
};
